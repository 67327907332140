
import './App.css';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import Home from "./pages/Home";
import Daily from "./pages/Daily";
import Start from "./pages/Start";
import TAS from "./pages/TAS";
import Prayer from "./pages/Prayer";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,

} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";


function App() {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: "us-east-1:d45ed742-9a2a-482b-9983-8c526a305ba1",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };
  
    const APPLICATION_ID = 'ff7c48dd-0141-4402-a41e-471891fabf83';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';
  
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }


  const router = createBrowserRouter([



    {
      path: "/",
      element: <Start />,
    },
    {
      path: "/Home",
      element: <Home />,
    },
    {
      path: "/Daily",
      element: <Daily />,
    },
    {
      path: "/TAS",
      element: <TAS />,
    },
    {
      path: "/Prayer",
      element: <Prayer />,
    },

  ]);



  createRoot(document.getElementById("root")).render(


    <RouterProvider router={router} />


  );



}
export default App;



