import AudioPlayer from "../../components/Audio/AudioPlayer";
import React, { useState, useEffect } from "react";
import {

  Box,
  Header,
  Button,
  Container,
  Form,
  SpaceBetween,
  ColumnLayout,
  HelpPanel,
  ContentLayout,
  AppLayout,


} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import '../locales/index';
import { useTranslation } from 'react-i18next';
import Navigation from "../../Navigation";
import { Authenticator } from "@aws-amplify/ui-react";
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';



const Content = (lang) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const { t, i18n } = useTranslation();
  const [pl, setPl] = React.useState([]);
  I18n.setLanguage(lang);
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);

  

  //  "prayerserenity": "The Serenity Prayer",
  //  "prayerlordsprayer": "The Lords Prayer",
  //  "prayerchristbewithme": "Christ be with me",
  //  "prayerguardianangel": "Guardian Angel Prayer",
  //  "prayeractofcontrition": "Act of Contrition",
  // "prayeractoflove": "Act of Love",
  //  "prayertherosary": "The Rosary",
  //   "prayer23rdpsalm": "Psalm 23"

  //console.log(lang['lang']);
  //console.log("/Prayer/" + lang['lang'] + "/daily.mp3");
  var lang = lang['lang'];
  console.log(t('prayerScreen.prayerlordsprayer'));
  var list =
    [
      {
        id: "1",
        key: "1",
        title: t('prayerScreen.prayerlordsprayer'),
        src: "/Prayer/" + lang + "/daily.mp3",
      },
      {
        id: "2",
        key: "2",
        title: t('prayerScreen.prayer23rdpsalm'),
        src: "/Prayer/" + lang + "/anxiety.mp3",
      },
      {
        id: "3",
        key: "3",
        title: t('prayerScreen.prayeractoflove'),
        src: "/Prayer/" + lang + "/love.mp3",
      },
      {
        id: "4",
        key: "4",
        title: t('prayerScreen.prayerserenity'),
        src: "/Prayer/" + lang + "/serenity.mp3",
      },
      {
        id: "5",
        key: "5",
        title: t('prayerScreen.prayeractofcontrition'),
        src: "/Prayer/" + lang + "/mercy.mp3",
      },
      {
        id: "6",
        key: "6",
        title: t('prayerScreen.prayerchristbewithme'),
        src: "/Prayer/" + lang + "/salvation.mp3",
      },
      {
        id: "7",
        key: "7",
        title: t('prayerScreen.prayertherosary'),
        src: "/Prayer/" + lang + "/foregiveness.mp3",
      },
      {
        id: "8",
        key: "8",
        title: t('prayerScreen.prayerguardianangel'),
        src: "/Prayer/" + lang + "/guidance.mp3",
      },

    ]


  console.log('pl ' + JSON.stringify(pl));






  return (


    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <div className="bg-black w-screen h-screen flex items-center justify-center">
            <ColumnLayout columns={2}>
              <Box fontSize="display-m" fontWeight="bold" textAlign="center">

              </Box>
              <Box fontSize="display-m" textAlign="center">

              </Box>
            </ColumnLayout>

            <Box textAlign="center">
              <AudioPlayer pl={list} />
            </Box>

            <Form
              actions={
                <ColumnLayout columns={2}>

                  <Button onClick={(event) => navigate("/Home")} variant="link">
                    {t('menuScreen.menuhome')}
                  </Button>

                </ColumnLayout>
              }
            >

            </Form>
          </div>
        </main>


      )}
    </Authenticator>



  );
}
const SideHelp = (lang) => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);
  console.log('Prayer SideHelp '+t('homeScreen.help0'));

  return (
    <HelpPanel header={<h2> {t('Prayer.help0')}</h2>}>
      <ContentLayout>
        <Container
          header={
            <Header variant="h4" description="">
              {t('Prayer.help1')}
            </Header>
          }
        >
          <SpaceBetween size="m">
            <hr />
            <Box>
              {t('Prayer.help2')}
              <br />
            </Box>
            <hr />
            <Box>
              {t('Prayer.help3')}
              <br />
            </Box>
          </SpaceBetween>
          <hr />
          <Box> {t('Prayer.help4')}</Box>
        </Container>
      </ContentLayout>
    </HelpPanel>

  );
}
function Prayer() {

  console.log("Prayer -> START");
  const { t, i18n } = useTranslation();
 
  const [lang, setLang] = React.useState(localStorage.getItem('lang'));
  I18n.setLanguage(lang);
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);

  console.log("Prayer -> " + lang);
  console.log("Prayer -> " + t('homeScreen.help4'));

  const [lnavopen, setLnavopen] = useState(false);

  return (
    <AppLayout
      disableContentPaddings={false}
      navigation={<Navigation lang={lang} />}
      content={<Content lang={lang} />}
      contentType="default"
      tools={<SideHelp lang={lang} />}
      navigationOpen={lnavopen}
      onNavigationChange={({ detail }) => setLnavopen(detail.open)}

    />
  );
}
export default Prayer;
