import AudioPlayer from "../../components/Audio/AudioPlayer";
import React, { useState, useEffect } from "react";
import {

  Box,
  Header,
  Button,
  Container,
  Form,
  SpaceBetween,
  ColumnLayout,
  HelpPanel,
  ContentLayout,
  AppLayout,


} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import '../locales/index';
import { useTranslation } from 'react-i18next';
import tas from "./tas-portrait.png";
import Navigation from "../../Navigation";
import { Authenticator } from "@aws-amplify/ui-react";
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';


const Content = (lang) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const { t, i18n } = useTranslation();
  I18n.setLanguage(lang);
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);



  var list = [
    {
      id: "1",
      key: "1",
      title: t('TAS.sermon1'),
      src: "/TAS/AFinalShaking.mp3",
    },
    {
      id: "2",
      key: "2",
      title: t('TAS.sermon2'),
      src: "/TAS/LetUsGoOn.mp3",
    },
    {
      id: "3",
      key: "3",
      title: t('TAS.sermon3'),
      src: "/TAS/Intercession.mp3",
    },
    
  ];

  return (

   
      <Authenticator>
        {({ signOut, user }) => (
          <main>
             <div className="TAS bg-black w-screen h-screen flex items-center justify-center">
            <ColumnLayout columns={2}>
              <Box fontSize="display-m" fontWeight="bold" textAlign="center">
                <img
                  className="centerImage"
                  src={tas}
                  alt="tas"

                />
              </Box>
              <Box fontSize="display-m" textAlign="center">
                <Header> {t('TAS.tas-bio')}</Header>
              </Box>
            </ColumnLayout>

            <Box textAlign="center">
              <AudioPlayer pl={list}/>
            </Box>

            <Form
              actions={
                <ColumnLayout columns={2}>

                  <Button onClick={(event) => navigate("/Home")} variant="link">
                    {t('menuScreen.menuhome')}
                  </Button>

                </ColumnLayout>
              }
            >

            </Form>
            </div>
          </main>
             
         
        )}
      </Authenticator>
     
 

  );
}
const SideHelp = (lang) => {

  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);

  console.log('TAS SideHelp '+t('homeScreen.help0'));

  return (
    <HelpPanel header={<h2> {t('TAS.help0')}</h2>}>
      <ContentLayout>
        <Container
          header={
            <Header variant="h4" description="">
              {t('TAS.help1')}
            </Header>
          }
        >
          <SpaceBetween size="m">
            <hr />
            <Box>
              {t('TAS.help2')}
              <br />
            </Box>
            <hr />
            <Box>
              {t('TAS.help3')}
              <br />
            </Box>
          </SpaceBetween>
          <hr />
          <Box> {t('TAS.help4')}</Box>
        </Container>
      </ContentLayout>
    </HelpPanel>

  );
}
function TAS() {

  console.log("TAS -> START");
  const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState(localStorage.getItem('lang'));
  console.log("TAS -> " + lang);
  console.log("TAS -> " + t('homeScreen.help4'));
 
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);

  const [lnavopen, setLnavopen] = useState(false);

  return (
    <AppLayout
      disableContentPaddings={false}
      navigation={<Navigation lang={lang} />}
      content={<Content lang={lang} />}
      contentType="default"
      tools={<SideHelp lang={lang} />}
      navigationOpen={lnavopen}
      onNavigationChange={({ detail }) => setLnavopen(detail.open)}

    />
  );
}
export default TAS;
