import React, { useState, useEffect } from "react";
import "./index.css"
import Home from "../Home"

import {
  RadioGroup,
  Alert,
  Header,
  Button,
  Container,
  TextContent,
  SpaceBetween,
  Box,
  ColumnLayout,
} from "@cloudscape-design/components";
import '../locales/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { I18n } from 'aws-amplify/utils';


var lang = "";
function Start() {
  console.log('Start -> Start');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [items, setItems] = useState({ 'lang': localStorage.getItem('lang') });
  const [alertVisible, setAlertVisible] = useState(false);



  // remove all
  //localStorage.clear();
  //localStorage.removeItem('lang');
  //


  const navigateHome = () => {
    console.log('Start -> navigateHome ');
    navigate("/Home");
  }
  const startOver = () => {
    console.log('Start -> startOver ');
    lang="";
    localStorage.clear();
    navigate("/");

  }

  const handleLanguageClick = (detail) => {

    console.log('Start -> handleLanguageClick detail ' + detail);
    //console.log('Start -> handleLanguageClick items ' + items['lang']);
    setAlertVisible(true);
    i18n.changeLanguage(detail);
    I18n.setLanguage(detail);
    console.log(t('LanguageSelection.lang1'));
    //
    // set lang
    //
    lang = detail;


  };
  const handleAlertClick = (detail) => {
    try {
      console.log('Start -> handleAlertClick language ' + detail);
      setAlertVisible(false);

      if (detail === 'yes') {
        console.log('Start -> Handled Alert Click ' + detail);
        //
        // Set Local Storage
        //
        localStorage.setItem('lang', lang);

        navigate("/Home");
      }
      else {
        console.log('User responds No to Selected Language');

      }

    } catch (e) {
      console.log('Start -> handleAlertClick Exception');
      console.log(e);

    }

  };


  
  if (localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== null) {
    console.log(' local storage found ' + items['lang']);
    var languageMessage = ''
    if (items['lang'] === 'es') {

      languageMessage = 'El idioma seleccionado es Español. Si esto es correcto, seleccione Continuar o seleccione Comenzar de nuevo.';
    } else if (items['lang'] === 'en') {
      languageMessage = 'Your selected language is English. If this is correct, select Continue else select Start Over';
    } else if (items['lang'] === 'pt') {
      languageMessage = 'Seu idioma selecionado é Portuguesa. Se isso estiver correto, selecione Continuar, caso contrário, selecione Recomeçar';
    } else if (items['lang'] === 'ru') {
      languageMessage = 'Выбранный вами язык — русский. Если это верно, выберите «Продолжить» или выберите «Начать сначала».';
    }



    return (
      <Container
        header={
          <Header
            variant="h2"
            description=""
          >
         {languageMessage}
          </Header>
        }
      >
        <Box margin="xxl" padding="xxl">
          <ColumnLayout columns={2}>
            <Button variant="primary" onClick={navigateHome}>
              Continue
            </Button>
            <Button variant="warning" onClick={startOver}>
              Start Over
            </Button>
          </ColumnLayout>

        </Box>

      </Container>
    );
  } else {

    return (

      <Container
        header={
          <Header
            variant="h2"
            description={t('LanguageSelection.description')}
          >
            {t('LanguageSelection.title')}
          </Header>
        }
      >
        <Box margin="xxl" padding="xxl">
          <RadioGroup
            onChange={({ detail }) => handleLanguageClick(detail.value)}
            value={lang}
            items={[
              { value: "en", label: "English" },
              { value: "ru", label: "Russian" },
              { value: "es", label: "Spanish" },
              { value: "pt", label: "Portuguese" },

            ]}

          />
        </Box>
        <SpaceBetween>
          <Alert
            statusIconAriaLabel="Info"
            action={<RadioGroup
              onChange={({ detail }) => handleAlertClick(detail.value)}
              value={lang}
              items={[
                { value: "yes", label: t('LanguageSelection.yes') },
                { value: "no", label: t('LanguageSelection.no') },


              ]}
            />
            }
            header={t('LanguageSelection.alertTitle')}
            visible={alertVisible}
          >
            {t('LanguageSelection.alert')}

          </Alert>
        </SpaceBetween>
      </Container>
    );
  }



}


export default Start;

