import React, { useState, useEffect } from "react";
import "./index.css"
import { Authenticator } from '@aws-amplify/ui-react';
import {
  AppLayout,
  Box,
  Header,
  HelpPanel,
  Button,
  Container,
  Grid,
  SpaceBetween,
  ContentLayout,
} from "@cloudscape-design/components";
import Navigation from "../../Navigation";
import '../locales/index';
import { useTranslation } from 'react-i18next';
import jcImage from "./faceofjesus.gif";
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import { Amplify} from 'aws-amplify';
import outputs from '../../aws-exports.js';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from "react-router-dom";

I18n.putVocabularies(translations);
I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",

  },
  es: {
    'Sign In': 'Registrarse',
    'Sign Up': 'Regístrate',
  },
});
Amplify.configure(outputs);

const Content = (lang) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const { t, i18n } = useTranslation();
  I18n.setLanguage(lang['lang']);
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);
  //console.log("Home -> Content -> lang" + lang['lang']);
  //console.log("Home -> Content ->" + i18n.language);
  //console.log('Home Content '+t('homeScreen.help0'));

  const goToDailyBread = () => {
    navigate("/Daily");
  }

  return (
    <div>
      <Authenticator>
        {({ signOut, user }) => (
          <main>
            <button onClick={signOut}> {t('LanguageSelection.signOut')}</button>
            <Grid gridDefinition={[
              { colspan: { default: 16, xxs: 8 } }
            ]}>
            <ContentLayout>
              <Container className="home" fitHeight>
                <Box fontSize="display-m" fontWeight="bold" textAlign="center">
                  {t('homeScreen.enterText')}

                </Box>
                <Box fontSize="display-m" fontWeight="bold" textAlign="center">
                  <img
                    className="centerImage"
                    onClick={goToDailyBread}
                    src={jcImage}
                    alt="JC"
                  />
                </Box>
                <Box fontSize="display-s" fontWeight="bold" textAlign="center">

                  <Button href="https://d38sagu538xqn9.cloudfront.net/terms_and_conditions_for_a_website.pdf" variant="link" iconAlign="right" iconName="external" target="_blank">
                    {t('homeScreen.terms')}
                  </Button>
                </Box>
              </Container>

            </ContentLayout>
            </Grid>
          </main>
        )}
      </Authenticator>
    </div>
  );


}

const SideHelp = (lang) => {
  console.log('Home -> SideHelp -> lang '+lang['lang']);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);
  console.log('Home SideHelp '+t('homeScreen.help0'));
  return (
    <div>
      <HelpPanel header={<h2> {t('homeScreen.help0')}</h2>}>
        <ContentLayout>
          <Container
            header={
              <Header variant="h4" description="">
                {t('homeScreen.help1')}
              </Header>
            }
          >
            <SpaceBetween size="m">
              <hr />
              <Box>
                {t('homeScreen.help2')}
                <br />
              </Box>
              <hr />
              <Box>
                {t('homeScreen.help3')}
                <br />
              </Box>
            </SpaceBetween>
            <hr />
            <Box>
              {t('homeScreen.help4')}
            </Box>
          </Container>
        </ContentLayout>
      </HelpPanel>
    </div>

  );

}


function Home() {

  console.log("Home -> START");
  const { t, i18n } = useTranslation();
  
  const [lang, setLang] = React.useState(localStorage.getItem('lang'));
  console.log('getLocal lang '+lang);
  //I18n.setLanguage(lang);
  useEffect(() => {
    i18n.changeLanguage(lang['lang']);
  }, [i18n]);
  


  console.log("Home -> " + lang);
  console.log("Home -> " + t('homeScreen.help4'));
  
  const [lnavopen, setLnavopen] = useState(false);


  return (
    <AppLayout
      disableContentPaddings={false}
      navigation={<Navigation lang={lang} />}
      content={<Content lang={lang} />}
      contentType="default"
      tools={<SideHelp lang={lang} />}
      navigationOpen={lnavopen}
      onNavigationChange={({ detail }) => setLnavopen(detail.open)}

    />
  );
}
export default Home;

