import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
//const config = require("./en.json");
var en = require('./en.json');
var es = require('./es-MX.json');
var pt = require('./pt.json');
var ru = require('./ru.json');
 

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ru: {
    translation: ru,
  },
  pt: {
    translation: pt,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false
  }
});

