import { SideNavigation, SpaceBetween } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import './pages/locales/index';
import {useTranslation} from 'react-i18next';

const Navigation = (lang) => {
    console.log('Navigation lang ' + lang['lang']);
    const {t, i18n} = useTranslation();
    const [NavigationItems, setNavigationItems] = useState([]);
    const [language, setLanguage] = React.useState("");
    
    useEffect(() => {
        setLanguage(lang['lang']);
        i18n.changeLanguage(lang['lang']);
        let navigation_items = [];
        
            navigation_items.push({
                type: "section",
                
                text: t('homeScreen.matt1820'),
                expanded: true,
                items: [

                    
                    { type: "link", text: t('menuScreen.menuhome'), href: "/Home" },
                    { type: "link", text: t('menuScreen.menudailybread'), href: "/Daily" },
                    { type: "link", text: t('menuScreen.menuprayer'), href: "/Prayer" },
                    { type: "link", text: 'TAS', href: "/TAS" },
                    
                ]
            });
           
        
        setNavigationItems(navigation_items);
    }, []);

    return (
        <SpaceBetween>
            <SideNavigation activeHref={0} items={NavigationItems} />
        </SpaceBetween>
    );
};

    export default Navigation;
